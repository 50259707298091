import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugin/axios";

//import Bootstrap
import "jquery/dist/jquery.min.js";
// import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "@mdi/font/css/materialdesignicons.min.css";
import "aos/dist/aos.css";
import "aos/dist/aos.js";
// import VueGeolocation from "vue-browser-geolocation";

const app = createApp(App);
app.use(store);
app.use(router);
// app.use(VueGeolocation);

app.mount("#body");
