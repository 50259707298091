import axios from "axios";
import store from "../store";
import router from "../router";
import Swal from "sweetalert2";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

axios.interceptors.request.use((request) => {
  request["baseURL"] = "https://apihadir.palugate.com/";
  // request["baseURL"] = "http://localhost:8080/";
  if (store.getters["auth/token"]) {
    request.headers.common[
      "Authorization"
    ] = `Bearer ${store.getters["auth/token"]}`;
  }
  return request;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status, data } = error.response;

    if (status === 403) {
      Swal.fire({
        title: "warning",
        text: "Anda tidak memiliki hak untuk mengakses halaman ini!",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Go back!",
      }).then(async () => {
        router.push({
          name: "Dashboard",
        });
      });
      throw new Error("my error message");
    }

    if (status === 401) {
      Swal.fire({
        title: "Token Expired",
        text: "Token Anda sudah kadaluarsa, harap lakukan login kembali.",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Login",
      }).then(async () => {
        await store.dispatch("auth/logout");
        router.push({
          name: "Login",
        });
      });
      throw new Error("my error message");
    }

    if (status === 405) {
      Swal.fire({
        title: "warning",
        text: "Method yang anda request tidak ada!",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Go back!",
      });

      throw new Error("my error message");
    }

    if (status === 409) {
      Swal.fire({
        icon: "warning",
        title: "",
        text: typeof data === "string" ? data : data.message,
      });
    }

    if (status === 400) {
      Swal.fire({
        icon: "error",
        title: "",
        text: typeof data === "string" ? data : data.message,
      });
    }

    if (status === 401) {
      Swal.fire({
        icon: "error",
        title: "",
        text: typeof data === "string" ? data : data.message,
      });
    }

    if (status == 422) {
      Swal.fire({
        title: "Error",
        text: "Data gagal tersimpan!",
        icon: "danger",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Tutup!",
      });
    }

    if (status >= 500) {
      Swal.fire({
        icon: "error",
        title: "",
        text: data.message,
      });
    }

    return Promise.reject(error);
  }
);
