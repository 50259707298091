<template>
  <header id="header-section">
    <nav class="navbar navbar-expand-lg pl-3 pl-sm-0" id="navbar">
      <div class="container">
        <div class="navbar-brand-wrapper d-flex w-100">
          <img src="../public/img/logo-text.png" alt="" style="height: 50px" />
          <button
            class="navbar-toggler ml-auto"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="mdi mdi-menu navbar-toggler-icon"></span>
          </button>
        </div>
        <div
          class="collapse navbar-collapse navbar-menu-wrapper"
          id="navbarSupportedContent"
        >
          <ul
            class="navbar-nav align-items-lg-center align-items-start ml-auto"
          >
            <li
              class="d-flex align-items-center justify-content-between pl-4 pl-lg-0"
            >
              <div class="navbar-collapse-logo">
                <img
                  src="../public/img/logo-text.png"
                  alt=""
                  style="height: 50px"
                />
              </div>
              <button
                class="navbar-toggler close-button"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="mdi mdi-close navbar-toggler-icon pl-5"></span>
              </button>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#header-section"
                >Home <span class="sr-only">(current)</span></a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#features-section">Tentang</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#feedback-section">Pengumuman</a>
            </li>
            <li class="nav-item btn-contact-us pl-4 pl-lg-0">
              <button
                class="btn btn-success mr-1"
                data-toggle="modal"
                data-target="#cekBantuan"
              >
                Cek NIK
              </button>
              <button
                class="btn btn-warning"
                data-toggle="modal"
                data-target="#modalRegistrasi"
              >
                Daftar
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>

  <!-- render vue router -->
  <router-view></router-view>
</template>

<script>
import AOS from "aos";
import $ from "jquery";

export default {
  mounted: function () {
    AOS.init({
      offset: 120,
      delay: 0,
      duration: 1200,
      easing: "ease",
      once: true,
      mirror: false,
      anchorPlacement: "top-bottom",
      disable: "mobile",
    });
    // HEADER ANIMATION
    window.onscroll = function () {
      scrollFunction();
    };
    var element = document.getElementById("body");
    function scrollFunction() {
      if (
        document.body.scrollTop > 400 ||
        document.documentElement.scrollTop > 400
      ) {
        $(".navbar").addClass("fixed-top");
        element.classList.add("header-small");
        $("body").addClass("body-top-padding");
      } else {
        $(".navbar").removeClass("fixed-top");
        element.classList.remove("header-small");
        $("body").removeClass("body-top-padding");
      }
    }

    // SCROLLSPY
    $(document).ready(function () {
      $(".nav-link").click(function () {
        var t = $(this).attr("href");
        $("html, body").animate(
          {
            scrollTop: $(t).offset().top - 75,
          },
          {
            duration: 1000,
          }
        );
        $("body").scrollspy({ target: ".navbar", offset: $(t).offset().top });
        return false;
      });
    });

    //SIDEBAR-OPEN
    $("#navbarSupportedContent").on("hidden.bs.collapse", function () {
      $("body").removeClass("sidebar-open");
    });

    $("#navbarSupportedContent").on("shown.bs.collapse", function () {
      $("body").addClass("sidebar-open");
    });

    window.onresize = function () {
      var w = window.innerWidth;
      if (w >= 992) {
        $("body").removeClass("sidebar-open");
        $("#navbarSupportedContent").removeClass("show");
      }
    };
  },
};
</script>

<style>
</style>